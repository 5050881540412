.news-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columnas */
    gap: 20px;
    margin-top: 20px; /* Espacio entre el título y las noticias */
  }
  
  .news-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    height: 300px; /* Ajusta la altura para que las tarjetas tengan un tamaño uniforme */
    overflow: hidden; /* Evita que el contenido se desborde */
    text-decoration: none; /* Evita subrayado en el texto */
    color: inherit; /* Hereda el color del texto del contenedor */
    transition: background-color 0.3s; /* Efecto de transición al pasar el mouse */
  }
  
  .news-card:hover {
    background-color: #e0f7fa; /* Cambia el color al pasar el mouse */
  }
  
  .news-card h3 {
    margin: 0 0 10px;
    font-size: 1.1em; /* Tamaño de fuente más grande para los títulos */
  }
  
  .news-card p {
    flex-grow: 1; /* Permite que la descripción ocupe el espacio disponible */
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination button {
    background-color: #0056b3;
    color: white;
    border: none;
    padding: 10px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .pagination button:hover {
    background-color: #0477f3;
  }
  
  .pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  
  .login-button {
    background-color: #0056b3;
    color: white;
    border: none;
    padding: 10px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .login-button:hover {
    background-color: #0477f3; /* Color al pasar el mouse */
  }
  
  
  
  
  

