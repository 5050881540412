
  label {
    font-weight: bold;
    color: #333;
  }
  .container {
    margin-top: 30px;
    border: 2px solid #ddd; /* Añade un borde sólido de 2 píxeles de color gris claro */
    border-radius: 10px; /* Añade esquinas redondeadas */
    padding: 20px; /* Añade relleno al contenedor */
  }